<template>
    <div class="header __container">
        <a-logo></a-logo>
        <div class="center">
            <header-nav></header-nav>
        </div>
        <div class="right">
            <drawer-view @view="drawer=true"></drawer-view>
        </div>
    </div>
</template>
<script>
import drawerView from './components/drawer-view';
import headerNav from './components/header-nav';

export default {
    name: "header-warp",
    components: {
        drawerView,
        headerNav
    },
    data() {
        return {
            drawer: false
        }
    }
}
</script>
<style scoped lang="less">
.header {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 200;
    left: 50%;
    transform: translateX(-50%);
    .center {
        display: block;
    }
    .right {
        display: flex;
        align-items: center;
    }
}

@media screen and(max-width: 720px) {
    .header {
        .center {
            display: none;
        }
    }
}

@media screen and(min-width: 720px) {
    .header {
        .center {
            display: none;
        }
    }
}

@media screen and(min-width: 920px) {
    .header {
        .center {
            display: none;
        }
    }
}

@media screen and(min-width: 920px) {
    .header {
        .center {
            display: block;
        }
    }
}

@media screen and(min-width: 1220px) {
    .header {
        .center {
            display: block;
        }
    }
}

@media screen and(min-width: 1440px) {
    .header {
        .center {
            display: block;
        }
    }
}
</style>
