<template>
    <div class="main">
        <div :class="{show: loading}" class="" v-show="loading">
            <banner></banner>
            <div class="__container">
                <provid></provid>
                <startTrading></startTrading>
                <global></global>
                <work></work>

                <our-partner></our-partner>
                <info></info>
                <footer-page></footer-page>
            </div>
        </div>
        <div class="loading" v-show="!loading">
            <loaderFlipDelay></loaderFlipDelay>
        </div>
    </div>
</template>

<script>
import headerWarp from '../header/header-warp';
import banner from "../banner/banner";
import introduce from "../introduce/introduce";
import provid from "../provid/provid";
import startTrading from '../start-trading/start-trading';
import global from "../global/global";
import work from "../work/work";
import news from './components/news';
import info from '../info/info';
import footerPage from "@/views/main-warp/components/footer";

import skFoldingCube from '../../components/loading/sk-folding-cube';
import loaderFlipDelay from '../../components/loading/loader-flipDelay';
import ourPartner from '../our-partner/our-partner';

export default {
    components: {
        headerWarp,
        banner,
        introduce,
        provid,
        startTrading,
        global,
        work,
        news,
        info,
        footerPage,
        skFoldingCube,
        loaderFlipDelay,
        ourPartner
    },
    name: "main-warp",
    data() {
        return {
            loading: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.loading = true;
        }, 50);
    },
    methods: {},
}
</script>
<style scoped lang="less">
.main {
    width: 100%;
    overflow: hidden;
}

.loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--col-blue);
}


</style>
