<template>
    <div class="start-trading">
        <div class="title NoeDisplay-Bold wow animate__fadeInUp">
            Start trading your digital assets
            with Kryptos
        </div>
        <div class="codes-wrap">
<!--            <codes></codes>-->
        </div>
    </div>
</template>

<script>
import codes from "@/views/start-trading/components/codes";
import {WOW} from 'wowjs'

export default {
    name: "start-trading",
    components: {
        codes
    },
    mounted() {
        new WOW().init();
    }
}
</script>

<style scoped lang="less">
.start-trading {
    position: relative;
    padding-top: 100px;
    min-height: 500px;
    .title {
        width: 670px;
        margin: 0 auto;
        font-style: normal;
        font-weight: bold;
        font-size: 42px;
        line-height: 50px;
        text-align: center;
        color: var(--col-color);
    }

    .codes-wrap {
        width: 100%;
        height: 422px;
        padding-top: 80px;
        background-image: url("../../assets/images/trade/trade-bg.png");
        background-size: 100% 100%;
    }
}

@media screen and(max-width: 720px) {
    .start-trading {
        .title {
            width: 500px;
            font-size: 44px;
            line-height: 40px;
        }
    }
}

@media screen and(min-width: 720px) {
    .start-trading {
        .title {
            width: 670px;
            font-size: 42px;
            line-height: 50px;
        }
    }
}

@media screen and(min-width: 920px) {
    .start-trading {
        .title {
            width: 670px;
            font-size: 42px;
            line-height: 50px;
        }
    }
}

@media screen and(min-width: 1220px) {
    .start-trading {
        .title {
            width: 670px;
            font-size: 42px;
            line-height: 50px;
        }
    }
}
</style>
