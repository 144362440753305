<template>
    <div class="codes">
        <div id="container">

        </div>
    </div>
</template>

<script>
import 'prismjs/themes/prism-tomorrow.css';
import GDemo from '@glorious/demo';
import Prism from 'prismjs';

export default {
    name: "codes",
    data() {
        return {}
    },
    mounted() {
        this.$nextTick(() => {
            const demo = new GDemo('#container');
            const code = `
                let sum = (1u8..=1000u8)
                    .map(f32::from)
                    .collect::<Vec<f32>>()
                    .iter()
                    .fold(0.0, |acc, x| acc + (x.powf(8.0) - x.powf(1.0/8.0)));
                `
            const highlightedCode = Prism.highlight(
                code,
                Prism.languages.javascript,
                'javascript'
            );
            demo
                .openApp('editor', {minHeight: '350px', windowTitle: 'Code'})
                .write(highlightedCode)
                .end();
        })
    }
}
</script>

<style scoped lang="less">
.codes {
    width: 100%;
    //height: 400px;
}
</style>
