import talk from "@/assets/images/logo/talk.svg";
import kargo from "@/assets/images/logo/kargo.svg";
import dvp from "@/assets/images/logo/dvp.svg";
import ralFinance from "@/assets/images/logo/ral-finance.svg";
import soteria from "@/assets/images/logo/soteria.svg";
import ukhavati from "@/assets/images/logo/ukhavati.svg";
import kdag from "@/assets/images/logo/kdag.svg";
import zignaly from "@/assets/images/logo/zignaly.svg";
import deri from "@/assets/images/logo/deri.svg";
import arche from "@/assets/images/logo/arche.svg";
import thefore from "@/assets/images/logo/thefore.svg";
import crypt from "@/assets/images/logo/crypt.svg";
import kaka from "@/assets/images/logo/kaka.svg";
import vera from "@/assets/images/logo/vera.svg";
// import raydiusResearch from "@/assets/images/logo/raydius-research.png";
// import dotoracle from '@/assets/images/logo/dotoracle.png';
// import nextype from '@/assets/images/logo/nextype.svg';
// import vee from '@/assets/images/logo/vee.svg';
import nfans from '@/assets/images/logo/nfans.svg';
import strip from '@/assets/images/logo/strip.svg';

import dressshow from '@/assets/images/logo/dressshow.png';
import gamicText from '@/assets/images/logo/gamic-text.png';
import happylandlogo from '@/assets/images/logo/happylandlogo.png';
import LOGO_ACY from '@/assets/images/logo/LOGO_ACY.svg';
import Meli from '@/assets/images/logo/Meli.png';
import SocialFi from '@/assets/images/logo/SocialFi.png';
import SouniLogo from '@/assets/images/logo/Souni_Logo.svg';
import SynesisOneLogo from '@/assets/images/logo/SynesisOne-Logo.svg';
import Lightyear from '@/assets/images/logo/lightyear.svg';
import betamars from '@/assets/images/logo/betamars.svg';

const logoMap = [

    {src: dvp, class: 'dvp', path: 'https://dvpnet.io/'},
    // {src: kdag, class: 'kdag', path: 'https://kdag.io/'},
    {src: ralFinance, class: 'ralFinance', path: 'https://rai.finance/'},

    {src: soteria, class: 'soteria', path: 'https://soteria.finance/'},
    {src: ukhavati, class: 'ukhavati', path: 'https://sukhavati.io/'},
    {src: kargo, class: 'kargo', path: 'https://dkargo.io/'},

    {src: arche, class: 'arche', path: 'https://arche.network/'},
    {src: thefore, class: 'thefore', path: 'https://www.theforce.trade/'},
    // {src: crypt, class: 'crypt', path: 'https://cryptofifa.com/'},

    {src: kaka, class: 'kaka', path: 'https://kakanft.com/'},
    {src: deri, class: 'deri', path: 'https://deri.finance/'},
    {src: zignaly, class: 'zignaly', path: 'https://zignaly.com/'},

    {src: talk, class: 'talk', path: 'https://talken.io/'},
    {src: vera, class: 'vera', path: 'https://vera.financial/'},
    // {src: raydiusResearch, class: 'raydius-research', path: 'http://www.raydius-research.com/#/'},
    // {src: dotoracle, class: 'dotoracle', path: 'https://dotoracle.network/'},
    // {src: nextype, class: 'nextype', path: 'https://nextype.finance/'},
    // {src: vee, class: 'vee', path: 'https://vee.finance'},
    {src: nfans, class: 'vee', path: 'http://www.nfans.io/'},
    {src: strip, class: 'vee', path: 'https://www.strip.finance/'},



    {src: dressshow, class: 'dressshow', path: 'http://nftdress.show'},
    {src: gamicText, class: 'gamicText', path: 'https://gamic.app'},
    {src: happylandlogo, class: 'happylandlogo', path: 'https://happyland.finance'},
    {src: LOGO_ACY, class: 'LOGO_ACY', path: 'https://acy.finance'},
    {src: Meli, class: 'Meli', path: 'https://meli.games'},
    {src: SocialFi, class: 'SocialFi', path: 'https://socialfi.ai'},
    {src: SouniLogo, class: 'SouniLogo', path: 'https://souni.io'},
    {src: SynesisOneLogo, class: 'SynesisOneLogo', path: 'https://www.synesis.one/'},
    {src: Lightyear, class: 'lightyear', path: 'https://lightyear.game'},
    {src: betamars, class: 'lightyear', path: 'https://betamars.io'},
]
export {logoMap}
