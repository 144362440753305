<template>
    <div class="provid" id="provid">
        <div class="title NoeDisplay-Bold wow animate__fadeInUp">
            Providing Access to Alternative Digital Assets
        </div>
        <div class="text Gilroy-SemiBold wow animate__fadeInUp">
            Fully customizable and neatly organized components
            will help you work faster without limiting creative freedom.
        </div>
        <div class="row">
            <div class="item">
                <div class="top Inter-Bold-slnt wow animate__fadeInUp">
                    <v-countup :duration="4" :start-value="0" :end-value="70"></v-countup>
                </div>
                <div class="bottom Gilroy-SemiBold wow animate__fadeInUp">
                    Projects invested
                </div>
            </div>
            <div class="item">
                <div class="top Inter-Bold-slnt wow animate__fadeInUp">
                    <v-countup :duration="4" :start-value="0" :end-value="50000"></v-countup>
                </div>
                <div class="bottom Gilroy-SemiBold wow animate__fadeInUp">
                    Community access
                </div>
            </div>
            <div class="item">
                <div class="top Inter-Bold-slnt wow animate__fadeInUp">
                    <v-countup :duration="4" :start-value="0" :end-value="70"></v-countup>
                </div>
                <div class="bottom Gilroy-SemiBold wow animate__fadeInUp">
                    Seasoned professionals
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {WOW} from 'wowjs';
import vCountup from 'vue-countupjs';

export default {
    name: "provid",
    components: {
        vCountup
    },
    data() {
        return {}
    },
    mounted() {
        new WOW().init();
    }
}
</script>

<style scoped lang="less">
.provid {
    margin-top: 170px;

    .title {
        font-weight: bold;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        letter-spacing: 0.33px;
        color: #99EFD0;
    }

    .text {
        width: 414px;
        margin: 0 auto;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.2px;
        color: var(--col-white);
        padding: 34px 0 48px 0;
    }

    .row {
        display: flex;
        justify-content: space-between;

        .item {
            width: 291px;
            height: 118px;

            .top {
                font-weight: bold;
                font-size: 36px;
                line-height: 42px;
                text-align: center;
                letter-spacing: 0.33px;
                color: var(--col-color);
                padding-bottom: 16px;

            }

            .bottom {
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.2px;
                color: var(--col-white)

            }
        }
    }
}


@media screen and(max-width: 720px) {
    .provid {
        .row {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .item {
                margin: 0 auto;
            }
        }
    }
}

@media screen and(min-width: 720px) {
    .provid {
        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

@media screen and(min-width: 920px) {
    .provid {
        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

@media screen and(min-width: 1220px) {
    .provid {
        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}
</style>
