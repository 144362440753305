<template>
    <div class="news">
        <div class="the-news">
            <span class="NoeDisplay-Bold wow animate__fadeInUp"> In The News</span>
            <span class="right">
                <img src="../../../assets/images/v2/arrow.svg" alt="">
                <span class="NoeDisplay-Bold"></span>
            </span>
        </div>
        <div class="row">
            <div class="tr row-top">
                <img src="../../../assets/images/v2/1.svg" alt="">
                <div class="content">
                    <span class="NoeDisplay-Bold wow animate__fadeInUp"> a crypto infrastructure data dredger </span>
                    <span class="Gilroy-SemiBold wow animate__fadeInUp">
                        Blocknative, a crypto infrastructure data dredger,Blocknative, a crypto infrastructure data dredger
                    </span>
                    <span class="Gilroy-SemiBold wow animate__fadeInUp">Jul.15.2021</span>
                </div>
            </div>
            <div class="tr row-top">
                <img src="../../../assets/images/v2/1.svg" alt="">
                <div class="content">
                    <span class="NoeDisplay-Bold wow animate__fadeInUp"> a crypto infrastructure data dredger </span>
                    <span class="Gilroy-SemiBold wow animate__fadeInUp">Blocknative, a crypto infrastructure data dredger,Blocknative, a crypto infrastructure data dredger
                    </span>
                    <span class="Gilroy-SemiBold wow animate__fadeInUp">Jul.15.2021</span>
                </div>
            </div>
        </div>
        <div class="row row-bottom">
            <div class="tr">
                <img src="../../../assets/images/v2/1.svg" alt="">
                <div class="content">
                    <span class="NoeDisplay-Bold wow animate__fadeInUp"> a crypto infrastructure data dredger </span>
                    <span class="Gilroy-SemiBold wow animate__fadeInUp">Blocknative, a crypto infrastructure data dredger,Blocknative, a crypto infrastructure data dredger
                    </span>
                    <span class="Gilroy-SemiBold wow animate__fadeInUp">Jul.15.2021</span>
                </div>
            </div>
            <div class="tr">
                <img src="../../../assets/images/v2/1.svg" alt="">
                <div class="content">
                    <span class="NoeDisplay-Bold wow animate__fadeInUp"> a crypto infrastructure data dredger </span>
                    <span class="Gilroy-SemiBold wow animate__fadeInUp">Blocknative, a crypto infrastructure data dredger,Blocknative, a crypto infrastructure data dredger
                    </span>
                    <span class="Gilroy-SemiBold wow animate__fadeInUp">Jul.15.2021</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {WOW} from 'wowjs'

export default {
    name: "news",
    mounted() {
        new WOW().init();
    }
}
</script>

<style scoped lang="less">
.news {
    padding: 50px 37px 0 37px;

    .the-news {
        position: relative;
        font-weight: bold;
        font-size: 42px;
        height: 50px;
        line-height: 50px;
        color: var(--col-color);
        margin-bottom: 30px;

        .right {
            position: absolute;
            right: 0;
            bottom: 0;
            color: var(--col-white);
            font-size: 12px;
            font-weight: bold;

            img {
                margin-left: 14px;
            }
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
        height: 166px;

        .tr {
            display: flex;
            height: 137px;

            .content {
                width: 205px;
                position: relative;
                margin-left: 16px;

                span {
                    display: block;
                }

                span:nth-of-type(1) {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0.02em;
                    color: var(--col-white);
                    padding-bottom: 9px;
                }

                span:nth-of-type(2) {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.002em;
                    color: var(--col-white);
                }

                span:nth-of-type(3) {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    color: #3EC997;
                }

            }
        }
    }

    .row-bottom {
        margin-top: 30px;
    }

    .row-top {
        position: relative;

        &::after {
            content: ' ';
            position: absolute;
            width: 80%;
            height: 1px;
            left: 0;
            bottom: -30px;
            border-bottom: 1px solid #3EC997;
        }
    }
}
</style>
