import { render, staticRenderFns } from "./main-warp.vue?vue&type=template&id=215e8aa4&scoped=true&"
import script from "./main-warp.vue?vue&type=script&lang=js&"
export * from "./main-warp.vue?vue&type=script&lang=js&"
import style0 from "./main-warp.vue?vue&type=style&index=0&id=215e8aa4&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "215e8aa4",
  null
  
)

export default component.exports