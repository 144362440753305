<template>
    <div class="footer DMSans-Regular">

        <span class="">© Kryptos 2021</span>
        <span class="policy">Privacy policy</span>

        <!--        <div class="list">-->
        <!--            <p class="wow animate__fadeInUp">Discover</p>-->
        <!--            <p class="wow animate__fadeInUp">About</p>-->
        <!--            <p class="wow animate__fadeInUp">Contact</p>-->
        <!--            <p class="wow animate__fadeInUp">Careers</p>-->
        <!--        </div>-->
        <!--        <div class="list">-->
        <!--            <p class="wow animate__fadeInUp">Work</p>-->
        <!--            <p class="wow animate__fadeInUp">Our Portfolio</p>-->
        <!--            <p class="wow animate__fadeInUp">Ventures</p>-->
        <!--            <p class="wow animate__fadeInUp">Brands</p>-->
        <!--        </div>-->
        <!--        <div class="list">-->
        <!--            <p class="wow animate__fadeInUp">Services</p>-->
        <!--            <p class="wow animate__fadeInUp">Privacy</p>-->
        <!--            <p class="wow animate__fadeInUp">Support</p>-->
        <!--            <p class="wow animate__fadeInUp">Help Desk</p>-->
        <!--        </div>-->
        <!--        <div class="list">-->
        <!--            <p class="wow animate__fadeInUp">Locations</p>-->
        <!--            <p class="wow animate__fadeInUp">Singapore</p>-->
        <!--            <p class="wow animate__fadeInUp">OCBC Center</p>-->
        <!--            <p class="wow animate__fadeInUp">Singapore 049513</p>-->
        <!--        </div>-->
        <!--        <div class="list">-->
        <!--            <div class="language">-->
        <!--                <img src="../../../assets/images/v2/lang.svg" alt="">-->
        <!--                <el-select class="__select" v-model="value" placeholder="请选择">-->
        <!--                    <el-option-->
        <!--                        v-for="item in options"-->
        <!--                        :key="item.lang"-->
        <!--                        :label="item.lang"-->
        <!--                        :value="item.lang">-->
        <!--                    </el-option>-->
        <!--                </el-select>-->
        <!--            </div>-->
        <!--            <div class="footer-logo">-->
        <!--                <img src="../../../assets/images/v2/footer-logo.svg" alt="">-->
        <!--            </div>-->

        <!--        </div>-->
    </div>
</template>

<script>

import {WOW} from 'wowjs';

export default {
    name: "footerComp",
    data() {
        return {
            value: 'EN',
            options: [
                {
                    lang: 'EN'
                },
                {
                    lang: 'CN'
                }
            ]
        }
    },
    mounted() {
        new WOW().init();
    }
}
</script>

<style scoped lang="less">
.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 80px 0 100px 0;
    font-size: 12px;

    span {
        display: block;
        padding: 5px 30px;
    }

    //.list {
    //    display: flex;
    //    align-items: start;
    //    flex-direction: column;
    //
    //    p:nth-of-type(1) {
    //        font-size: 14px;
    //        padding-bottom: 20px;
    //        font-family: DMSans-Medium;
    //        font-style: normal;
    //        font-weight: normal;
    //    }
    //
    //    p:nth-of-type(n+2) {
    //        font-size: 12px;
    //        padding-bottom: 10px;
    //        font-family: DMSans-Regular;
    //        font-style: normal;
    //        font-weight: normal;
    //
    //    }
    //
    //    .language {
    //        width: 100px;
    //        display: flex;
    //        align-items: center;
    //        padding-bottom: 40px;
    //    }
    //
    //    .footer-logo {
    //        display: flex;
    //        width: 100%;
    //        align-items: center;
    //        justify-content: center;
    //    }
    //}
}
</style>
