<template>
    <div class="header-nav">
        <span v-for="data in list" class="NoeDisplay-Bold" @click="scrollClick(data.id)">
            {{ data.text }}
        </span>
    </div>
</template>

<script>
import $ from 'jquery'
import {navList} from "@/assets/js/header-nav";

export default {
    name: "header-nav",
    data() {
        return {
            list: navList
        }
    },
    methods: {
        scrollClick(id) {
            $('html, body').animate({scrollTop: $('#' + id+ '').offset().top}, 1000);
        }
    }
}
</script>

<style scoped lang="less">
.header-nav {
    display: flex;
    align-items: center;

    span {
        cursor: pointer;
        display: block;
        padding: 5px 15px;
        color: var(--col-static-color);
    }
}

@media screen and(max-width: 920px) {
    .header-nav {

    }
}

@media screen and(min-width: 920px) {
    .header-nav {

        span {
            padding: 5px 10px;
            margin: 0 3px;
        }
    }
}

@media screen and(min-width: 1220px) {
    .header-nav {

        span {
            padding: 5px 13px;
            margin: 0 6px;
        }
    }
}

@media screen and(min-width: 1440px) {
    .header-nav {

        span {
            padding: 5px 15px;
            margin: 0 8px;
        }
    }
}
</style>
