<template>
    <div class="info">
        <div class="left">
            <div class="contrat NoeDisplay-Bold wow animate__fadeInUp">
                Contrat Us
            </div>
            <div class="text Gilroy-SemiBold wow animate__fadeInUp">
                Receive our newsletter to get the latest updates on the company.
            </div>
            <subscribe></subscribe>
        </div>
        <div class="right">
            <div class="contrat NoeDisplay-Bold wow animate__fadeInUp">
                Follow Us
            </div>
            <div class="text Gilroy-SemiBold wow animate__fadeInUp">
                Stay up to date on social media.
            </div>
            <icon></icon>
        </div>
    </div>
</template>

<script>
import icon from '../../components/icon';
import {WOW} from 'wowjs';
import subscribe from "@/views/info/components/subscribe";

export default {
    components: {
        icon,
        subscribe
    },
    name: "info",
    mounted() {
        new WOW().init();
    }
}
</script>

<style scoped lang="less">
.info {
    padding: 94px 37px 0 37px;
    display: flex;

    .left {
        width: 508px;
    }

    .contrat {
        font-weight: bold;
        font-size: 28px;
        line-height: 50px;
        color: var(--col-color);
    }

    .text {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
        padding: 10px 0 28px 0;
    }
}


@media screen and (max-width: 720px) {
    .info {
        padding: 94px 37px 0 37px;
        display: flex;
        flex-direction: column;

        .left {
            width: 508px;
        }
        .right{
            padding-top: 80px;
        }

        .contrat {
            text-align: center;
            font-size: 44px;
            line-height: 50px;
        }

        .text {
            text-align: center;
            font-size: 24px;
            line-height: 35px;
            padding: 10px 0 28px 0;
        }
    }
}

@media screen and (min-width: 720px) {
    .info {
        padding: 94px 37px 0 37px;
        display: flex;

        .left {
            width: 508px;
        }
        .right{
            padding-top: 0;
        }

        .contrat {
            font-weight: bold;
            font-size: 28px;
            line-height: 50px;
            color: var(--col-color);
        }

        .text {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #FFFFFF;
            padding: 10px 0 28px 0;
        }
    }
}

@media screen and (min-width: 920px) {
    .info {
        padding: 94px 37px 0 37px;
        display: flex;

        .left {
            width: 508px;
        }
        .right{
            padding-top: 0;
        }
        .contrat {
            font-weight: bold;
            font-size: 28px;
            line-height: 50px;
            color: var(--col-color);
        }

        .text {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #FFFFFF;
            padding: 10px 0 28px 0;
        }
    }
}

@media screen and (min-width: 1200px) {
    .info {
        padding: 94px 37px 0 37px;
        display: flex;

        .left {
            width: 508px;
        }
        .right{
            padding-top: 0;
        }
        .contrat {
            font-weight: bold;
            font-size: 28px;
            line-height: 50px;
            color: var(--col-color);
        }

        .text {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #FFFFFF;
            padding: 10px 0 28px 0;
        }
    }
}
</style>
