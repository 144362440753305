<template>
    <div class="introduce">
        <div class="left">
            <div class="title NoeDisplay-Bold wow animate__fadeInUp">What is Kryptos?</div>
            <div class="text Gilroy-SemiBold wow animate__fadeInUp">
                Kryptos is a trading firm specializing in cryptocurrencies and digital assets. It is the first-ever
                dedicated OTC trading desk to provide institutional-grade solutions.
            </div>
            <el-button class="__btn wow animate__fadeInUp" type="primary">
                <span class="NoeDisplay-Bold">Learn More</span>
            </el-button>
        </div>
        <div class="right">
            <img class="img wow animate__fadeInUp" src="../../assets/images/v2/introduce.svg" alt="">
<!--            <img class="little" src="../../assets/images/v2/little.svg" alt="">-->
            <svg class="little" width="142" height="156" viewBox="0 0 142 156" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="flashing" d="M139.965 1.70508V154.555" stroke="#13C296" stroke-width="2.68158" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.89 17.88"/>
                <path class="flashing"  d="M112.254 1.70508V154.555" stroke="#13C296" stroke-width="2.68158" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.89 17.88"/>
                <path class="flashing"  d="M84.5446 1.70508V154.555" stroke="#13C296" stroke-width="2.68158" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.89 17.88"/>
                <path class="flashing"  d="M56.8356 1.70508V154.555" stroke="#13C296" stroke-width="2.68158" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.89 17.88"/>
                <path class="flashing"  d="M29.1247 1.70508V154.555" stroke="#13C296" stroke-width="2.68158" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.89 17.88"/>
                <path class="flashing"  d="M1.41568 1.70508V154.555" stroke="#13C296" stroke-width="2.68158" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.89 17.88"/>
            </svg>
        </div>
    </div>
</template>

<script>
import {WOW} from 'wowjs';

export default {
    name: "introduce",
    data() {
        return {}
    },
    mounted() {
        new WOW().init();
    }
}
</script>

<style scoped lang="less">
.introduce {
    display: flex;
    justify-content: space-between;
    height: 399px;

    .left {
        width: 470px;
        padding-top: 20px;

        .title {
            font-weight: bold;
            font-size: 42px;
            line-height: 50px;
            color: var(--col-color);
            margin-bottom: 80px;
        }

        .text {
            font-weight: 600;
            font-size: 15px;
            line-height: 28px;
            letter-spacing: 0.5px;
            color: var(--col-white);
            padding-bottom: 24px;
        }
    }

    .right {
        width: 333px;
        height: 399px;
        position: relative;

        .img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }

        .little {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }
}

.aa{

}
</style>

