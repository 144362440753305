<template>
    <div class="banner" id="banner">
        <headerWrap></headerWrap>
        <three-canvas></three-canvas>
        <div class="__container banner-body">
            <banner-title></banner-title>
            <banner-content></banner-content>
        </div>
    </div>
</template>

<script>
import headerWrap from '../header/header-warp';
import logoList from './components/logo-list';
import threeCanvas from './components/three-canvas';
import bannerTitle from './components/banner-title';
import bannerContent from './components/banner-content';

export default {
    name: "banner",
    components: {
        logoList,
        threeCanvas,
        bannerTitle,
        bannerContent,
        headerWrap
    },
    mounted() {

    }
}
</script>

<style scoped lang="less">


.banner {
    width: 100%;
    min-height: 600px;
    position: relative;
    background: transparent;
    .banner-body {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

</style>
