<template>
    <div class="icon">
        <div class="svg">
            <a href="https://twitter.com/KryptosResearch" target="_blank">
                <img @click="view('https://twitter.com/KryptosResearch')"
                     class="twitter" src="../assets/images/v2/twitter.svg"
                     alt="">
            </a>
        </div>
        <div class="svg twitter">
            <a href="https://medium.com/@kryptosresearch" target="_blank">
                <img src="../assets/images/v2/m.svg" alt="">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "icon",
    data(){
        return {
            view(url){
                window.open(url);
            }
        }
    }
}
</script>

<style scoped lang="less">
.icon{
    display: flex;
    align-items: center;
    .svg{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--col-bg);
        border-radius: 50%;
    }
    .twitter{
        margin: 0 10px;
    }
}
@media screen and(max-width: 720px){
    .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        .svg{
            width: 70px;
            height: 70px;
        }
        .twitter{
            margin: 0 20px;
        }
    }
}
@media screen and(min-width: 720px){
    .icon{
        display: flex;
        align-items: center;
        justify-content: start;
        .svg{
            width: 40px;
            height: 40px;
        }
        .twitter{
            margin: 0 10px;
        }
    }
}
</style>
