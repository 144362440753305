<template>
    <div class="our-partner" id="our-partner">
        <div class="title NoeDisplay-Bold wow animate__fadeInUp">
            Portfolio
        </div>
        <div class="list">
            <div class="box" v-for="data in list">
                <img :class="['img', data.class]" :src="data.src" @click="view(data)">
            </div>
        </div>
    </div>
</template>

<script>
import {logoMap} from "@/assets/js/logoMap";

export default {
    name: "our-partner",
    data() {
        return {
            list: logoMap
        }
    },
    methods: {
        view(data) {
            window.open(data.path)
        }
    }
}
</script>

<style scoped lang="less">
.our-partner {
    .title {
        font-size: 42px;
        color: #99EFD0;
        padding: 100px 0 10px 33px;
    }

    .list {
        width: 100%;
        min-height: 200px;
        display: flex;
        flex-wrap: wrap;
    }

    .box {
        width: 120px;
        height: 120px;
        display: flex;
    }

    .img {
        width: 100px;
        height: 35px;
        margin: auto;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
        &.dressshow{
            width: 58px;
            height: 58px;
        }
        &.SocialFi{
            width: 60px;
            height: 30px;
        }
        &.Meli{
            width: 60px;
            height: 40px;
        }
    }
}

@media screen and(max-width: 720px) {
    .our-partner {
        .title {
            text-align: center;
            font-size: 44px;
            padding: 150px 0 50px 0;
        }
        .box {
            width: 200px;
            height: 150px;
            display: flex;
        }

        .img {
            width: 120px;
            height: 60px;
        }
    }
}

@media screen and(min-width: 1200px) {
    .our-partner {
        .title {
            font-size: 42px;
            padding: 100px 0 10px 33px;
        }

        .list {
            width: 100%;
            min-height: 200px;
        }

        .box {
            width: 120px;
            height: 120px;
            display: flex;
        }

        .img {
            width: 100px;
            height: 35px;
        }
    }
}
</style>
