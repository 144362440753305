<template>
    <div class="NoeDisplay-Bold wow animate__fadeInUp banner-title">
        A research-driven digital asset investment firm
    </div>
</template>

<script>
export default {
    name: "banner-title"
}
</script>

<style scoped lang="less">
.banner-title {
    width: 525px;
    color: var(--col-color);
    font-size: 44px;
    line-height: 56px;
    letter-spacing: 0.02em;
    padding-bottom: 40px;
    text-align: center;
    margin: 0 auto;
}
@media screen and(max-width: 720px) {
    .banner-title {
        width: 525px;
        font-size: 44px;
        line-height: 56px;
        padding-bottom: 40px;
    }
}

@media screen and(min-width: 720px) {
    .banner-title {
        width: 485px;
        font-size: 40px;
        line-height: 50px;
        padding-bottom: 35px;
    }
}

@media screen and(min-width: 920px) {
    .banner-title {
        width: 485px;
        font-size: 40px;
        line-height: 50px;
        padding-bottom: 35px;
    }
}

@media screen and(min-width: 1220px) {
    .banner-title {
        width: 525px;
        font-size: 44px;
        line-height: 56px;
        padding-bottom: 40px;
    }
}
</style>
