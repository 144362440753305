<template>
    <a class="subscribe" href="mailto:contact@kryptos.fund" target="_blank">
        <span class="bg"></span>
        <div class="text">
            Contact
            <img class="arrow" src="../../../assets/images/v2/arrow.svg" alt="">
        </div>
    </a>
</template>

<script>
export default {
    name: "subscribe"
}
</script>

<style scoped lang="less">
.subscribe {
    position: relative;
    width: 120px;
    height: 40px;
    cursor: pointer;
    display: flex;

    .arrow {
        margin-left: 10px;
        width: 18px;
        height: 18px;
    }

    .bg {
        display: block;
        width: 20%;
        height: 40px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        transition: width 2s;
        background: var(--col-blue);
    }

    .text {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        padding: 0 20px;
        color: white;
    }

    &:hover {
        .bg {
            width: 100%;
        }
    }
}

@media screen and(max-width: 720px) {
    .subscribe {
        width: 100%;
        height: 60px;
        .bg {
            width: 100%;
            height: 60px;
        }
    }
}
@media screen and(min-width: 720px) {
    .subscribe {
        position: relative;
        width: 120px;
        height: 40px;
        cursor: pointer;
        display: flex;

        .arrow {
            margin-left: 10px;
            width: 18px;
            height: 18px;
        }

        .bg {
            display: block;
            width: 20%;
            height: 40px;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            transition: width 2s;
            background: var(--col-blue);
        }

        .text {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 2;
            padding: 0 20px;
            color: white;
        }

        &:hover {
            .bg {
                width: 100%;
            }
        }
    }
}
</style>
