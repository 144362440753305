<template>
    <div class="global">
        <div class="title wow NoeDisplay-Bold animate__fadeInUp">
            We trade globally
        </div>
        <div class="content wow Gilroy-SemiBold animate__fadeInUp">
            With investors, partners and clients spreading over four continents, we are a leader in global trading. Having significant footprints in East and South Asia, we trade intensively with clients based in the Americas, as well as Europe.
        </div>
        <img class="global" src="../../assets/images/global/global.png" alt="">

    </div>
</template>

<script>
import {WOW} from 'wowjs';
export default {
    name: "global",
    mounted() {
        new WOW().init();
    }
}
</script>

<style scoped lang="less">
.global {
    position: relative;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 42px;
        line-height: 50px;
        color: var(--col-color);
        padding-bottom: 42px;
        //padding: 170px 0 62px 0;
    }
    .content {
        width: 512px;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: var(--col-white);
    }
    .global {
        position: absolute;
        right:-150px;
        top: 50%;
        transform: translateY(-50%);
    }
}
@media screen and(max-width: 720px) {
    .global {
        height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .title {
            font-size: 44px;
            line-height: 50px;
            padding-bottom: 20px;
        }
        .content {
            width: 450px;
            font-size: 14px;
            line-height: 22px;
        }
        .global {
            position: absolute;
            top:200px;
            left: 50%;
            transform: translateX(-50%);
            width: 480px;
            height: 480px;
        }
    }
}
@media screen and(min-width: 720px) {
    .global {
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .title {
            font-size: 44px;
            line-height: 50px;
            padding-bottom: 20px;
        }
        .content {
            width: 450px;
            font-size: 14px;
            line-height: 22px;
        }
        .global {
            position: absolute;
            top:100px;
            left: 50%;
            transform: translateX(-50%);
            width: 480px;
            height: 480px;
        }
    }
}
@media screen and(min-width: 920px) {
    .global {
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        .title {
            font-size: 42px;
            line-height: 50px;
            padding-bottom: 42px;
        }
        .content {
            width: 450px;
            font-size: 14px;
            line-height: 22px;
        }
        .global {
            position: absolute;
            right:-150px;
            top: 50%;
            transform: translateY(-50%);
            width: 480px;
            height: 480px;
        }
    }
}
@media screen and(min-width: 1220px) {
    .global {
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
            font-size: 42px;
            line-height: 50px;
            padding-bottom: 42px;
        }
        .content {
            width: 512px;
            font-size: 15px;
            line-height: 22px;
        }
        .global {
            position: absolute;
            right:-150px;
            top: 50%;
            transform: translateY(-50%);
            width: 580px;
            height: 580px;
        }
    }
}
</style>
