<template>

        <div class="str4 str_wrap" style="height: 100px;">
            <a href="#">
                <img class="img" v-for="data in logoMap" :src="data.src" alt="">
            </a>

        </div>

</template>

<script>
import {logoMap} from "@/assets/js/logoMap";

export default {
    name: "logo-list",
    data() {
        return {
            logoMap: logoMap
        }
    },
    mounted() {
        this.$nextTick(() => {
            $('.str4').liMarquee();
        })
    },
    methods: {},
}
</script>

<style scoped lang="less">
.str_wrap {
    overflow: hidden;
    width: 100%;
    height: 100px;
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: center;
}
.img{
    width: 150px;
    height: 40px;
}


.str_wrap.str_active {

}
.str_move {
    white-space:nowrap;
    position:absolute;
    top:0;
    left:0;
    cursor:move;
}
.str_move_clone {
    display:inline-block;
    //display:inline;
    //zoom:1;
    vertical-align:top;
    position:absolute;
    left:100%;
    top:0;
}
.str_vertical .str_move_clone {
    left:0;
    top:100%;
}
.str_down .str_move_clone {
    left:0;
    bottom:100%;
}
.str_vertical .str_move,
.str_down .str_move {
    white-space:normal;
    width:100%;
}
.str_static .str_move,
.no_drag .str_move,
.noStop .str_move{
    cursor:inherit;
}

</style>
