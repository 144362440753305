<template>
    <div class="work" id="work">
        <img class="work-img" src="../../assets/images/work/work.png" alt="">
        <div class="right">
            <div class="title NoeDisplay-Bold wow animate__fadeInUp">
                Our Team Work
            </div>
            <div class="content Gilroy-SemiBold wow animate__fadeInUp">
                The Kryptos team has a proprietary high-frequency trading (HFT) system to implement liquidity providing
                and
                market making strategies. We have served more than 30 projects, including some renowned ones, via both
                CEX and DEX.
            </div>

            <div class="feature">
                <div class="one">
                    <img class="" src="../../assets/images/v2/one.svg" alt="">
                    <div class="feature-title NoeDisplay-Bold wow  animate__fadeInUp">
                        Feature One
                    </div>
                    <div class="content Gilroy-SemiBold wow animate__fadeInUp">
                        currently managing more than 20 crypto communities, with access to more than 50k potential
                        audiences.
                    </div>
                </div>
                <div class="two">
                    <img src="../../assets/images/v2/two.svg" alt="">
                    <div class="feature-title NoeDisplay-Bold wow animate__fadeInUp">
                        Feature Two
                    </div>
                    <div class="content Gilroy-SemiBold wow animate__fadeInUp">
                        The team actively participates in many on-chain activities, including data analysis, event
                        tracking and governance.
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {WOW} from 'wowjs';

export default {
    name: "work",
    mounted() {
        new WOW().init();
    }
}
</script>

<style scoped lang="less">
.work {
    min-height: 446px;
    display: flex;
    justify-content: space-between;

    .work-img {
        display: block;
        border: none;
        width: 449px;
        max-width: 449px;
    }

    .right {
        width: 512px;
        margin-left: 84px;

        .title {
            font-weight: bold;
            font-size: 26px;
            line-height: 32px;
            letter-spacing: 0.325px;
            color: var(--col-color);
            padding: 53px 0 33px 0;
        }

        .content {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: var(--col-white);
            padding-bottom: 28px;
        }

        .feature {
            display: flex;
            justify-content: space-between;

            .one, .two {
                width: 224px;

                .feature-title {
                    padding: 16px 0 8px 0;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 18px;
                    letter-spacing: 0.2px;
                    color: var(--col-color);
                }
            }
        }
    }
}

@media screen and(max-width: 720px) {
    .work {
        min-height: 446px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;

        .work-img {
            width: 512px;
            max-width: 512px;
        }

        .right {
            width: 512px;
            margin-left: 0;

            .title {
                text-align: center;
                font-size: 44px;
                line-height: 55px;
                padding: 53px 0 33px 0;
            }

            .content {
                text-align: center;
                font-size: 15px;
                line-height: 28px;
                padding-bottom: 56px;
            }

            .feature {
                display: flex;
                flex-direction: column;
                align-items: center;

                .one, .two {
                    width: 100%;

                    img {
                        margin: 0 auto;
                        display: block;
                    }

                    .feature-title {
                        padding: 16px 0 30px 0;
                        font-size: 15px;
                        line-height: 18px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media screen and(min-width: 720px) {
    .work {
        min-height: 446px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;

        .work-img {
            width: 512px;
            max-width: 512px;
        }

        .right {
            width: 512px;
            margin-left: 84px;

            .title {
                font-weight: bold;
                font-size: 26px;
                line-height: 32px;
                letter-spacing: 0.325px;
                color: var(--col-color);
                padding: 53px 0 33px 0;
            }

            .content {
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: var(--col-white);
                padding-bottom: 28px;
            }

            .feature {
                display: flex;
                justify-content: space-between;

                .one, .two {
                    width: 224px;

                    .feature-title {
                        padding: 16px 0 8px 0;
                        font-weight: bold;
                        font-size: 15px;
                        line-height: 18px;
                        letter-spacing: 0.2px;
                        color: var(--col-color);
                    }
                }
            }
        }
    }
}

@media screen and(min-width: 920px) {
    .work {
        min-height: 446px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .work-img {
            width: 349px;
            height: 349px;
            max-width: 349px;
        }

        .right {
            width: 450px;
            margin-left: 50px;

            .title {
                font-size: 24px;
                padding: 30px 0 25px 0;
            }

            .content {
                font-size: 14px;
                padding-bottom: 20px;
            }

            .feature {
                display: flex;
                justify-content: space-between;
                flex-direction: row;

                .one, .two {
                    width: 224px;

                    .feature-title {
                        padding: 16px 0 8px 0;
                        font-size: 15px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

@media screen and(min-width: 1220px) {
    .work {
        min-height: 446px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .work-img {
            width: 449px;
            height: 449px;
            max-width: 449px;
        }

        .right {
            width: 512px;
            margin-left: 84px;

            .title {
                font-size: 26px;
                line-height: 32px;
                padding: 53px 0 33px 0;
            }

            .content {
                font-size: 15px;
                line-height: 20px;
                padding-bottom: 28px;
            }

            .feature {
                display: flex;
                justify-content: space-between;
                flex-direction: row;

                .one, .two {
                    width: 224px;

                    .feature-title {
                        padding: 16px 0 8px 0;
                        font-size: 15px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

</style>
