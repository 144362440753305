<template>
    <div>
        <img @click="view" class="view" src="../../../assets/images/header/view.png">
        <el-drawer
            class="__el-drawer"
            :visible.sync="drawer"
            :size="'100%'"
            :with-header="false"
            :append-to-body="true"
        >

            <div class="drawer-body">
                <div class="drawer-header">
                    <a-logo></a-logo>
                    <img @click="closeView" class="close-view" src="../../../assets/images/header/close.png" alt="">
                </div>
                <div class="nav">
                    <span v-for="data in list" class="NoeDisplay-Bold" @click="scrollClick(data.id)">
                        {{ data.text }}
                    </span>
                </div>
            </div>
        </el-drawer>
    </div>

</template>

<script>

import {navList} from "@/assets/js/header-nav";
import $ from "jquery";

export default {
    name: "drawer-view",
    components: {},
    data() {
        return {
            drawer: false,
            list: navList
        }
    },
    methods: {
        view() {
            this.drawer = true;
        },
        handleClose(done) {
            done();
        },
        scrollClick(id) {
            $('html, body').animate({scrollTop: $('#' + id + '').offset().top}, 1000);
            this.closeView();
        },
        closeView() {
            this.drawer = false;
        }
    }
}
</script>

<style scoped lang="less">
.view {
    width: 30px;
    margin-left: 15px;
    display: none;
    cursor: pointer;
}

@media screen and(max-width: 920px) {
    .view {
        width: 30px;
        margin-left: 15px;
        display: block;
    }
}

@media screen and(min-width: 920px) {
    .view {
        display: none;
    }
}

@media screen and(min-width: 1220px) {
    .view {
        display: none;
    }
}

@media screen and(min-width: 1440px) {
    .view {
        display: none;
    }
}

</style>
