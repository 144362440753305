<template>
    <div class="Gilroy-SemiBold wow animate__fadeInUp banner-content">
        Founded in Oct 2020, Kryptos is a research-driven digital
        asset investment firm. We have invested in more than 70 projects, covering sectors ranging from
        GameFi, DeFi, SocialFi, Web3, to
        blockchain infrastructures.
    </div>
</template>

<script>
export default {
name: "banner-content"
}
</script>

<style scoped lang="less">
.banner-content{
    text-align: center;
    width: 790px;
    font-size: 18px;
    line-height: 22px;
    color: var(--col-white);
    padding-bottom: 24px;
    margin: 0 auto;
}

@media screen and(max-width: 720px) {
    .banner-content{
        width: 590px;
        font-size: 14px;
        line-height: 24px;
    }
}

@media screen and(min-width: 720px) {
    .banner-content{
        width: 690px;
        font-size: 18px;
        line-height: 22px;
    }
}

@media screen and(min-width: 920px) {
    .banner-content{
        width: 790px;
        font-size: 18px;
        line-height: 22px;
    }
}

@media screen and(min-width: 1220px) {
    .banner-content{
        width: 790px;
        font-size: 18px;
        line-height: 22px;
    }
}
</style>
