<template>
    <div class="three w-embed">
        <canvas class="webgl" width="2532" height="1786" style="width: 1266px; height: 893px;"></canvas>
    </div>
</template>

<script>
export default {
    name: "three-canvas",
    mounted() {
        this.$nextTick(() => {

            const dom = document.getElementById('banner');
            const height = document.documentElement.clientHeight || document.body.clientHeight;
            dom.style.height = height - 100 + 'px';
            const canvas = document.querySelector('canvas.webgl')
            const scene = new THREE.Scene()
            const geometry = new THREE.SphereGeometry(.7, 40, 20, 4, 4, 0, 4);
            const particlesGeometry = new THREE.BufferGeometry;
            const particlesCnt = 3000;
            const posArray = new Float32Array(particlesCnt * 3);

            for (let i = 0; i < particlesCnt * 3; i++) {
                // posArray[i] = Math.random() - 0.5
                posArray[i] = (Math.random() - 0.5) * (Math.random() * 5)
            }

            particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3))

            // Materials

            const material = new THREE.PointsMaterial({
                size: 0.005,
                color: '#99EFD0'// 0xffffff
            })

            const particlesMaterial = new THREE.PointsMaterial({
                size: 0.005,
                color: '#99EFD0'
            })


            // Mesh
            const sphere = new THREE.Points(geometry, material)
            const particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial)
            scene.add(sphere, particlesMesh)

            // Lights

            const pointLight = new THREE.PointLight(0xffffff, 0.1)
            pointLight.position.x = 2
            pointLight.position.y = 3
            pointLight.position.z = 4
            scene.add(pointLight)

            /**
             * Sizes
             */
            const sizes = {
                width: window.innerWidth,
                height: window.innerHeight
            }

            window.addEventListener('resize', () => {
                // Update sizes
                sizes.width = window.innerWidth
                sizes.height = window.innerHeight

                // Update camera
                camera.aspect = sizes.width / sizes.height
                camera.updateProjectionMatrix()

                // Update renderer
                renderer.setSize(sizes.width, sizes.height)
                renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
            })

            /**
             * Camera
             */
// Base camera
            const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
            camera.position.x = 0
            camera.position.y = 0
            camera.position.z = 2
            scene.add(camera)

            // Controls
            // const controls = new OrbitControls(camera, canvas)
            // controls.enableDamping = true

            /**
             * Renderer
             */
            const renderer = new THREE.WebGLRenderer({
                canvas: canvas,
                alpha: true
            })
            renderer.setSize(sizes.width, sizes.height)
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

            /**
             * Animate
             */

            document.addEventListener('mousemove', animateParticles)

            let mouseX = 0
            let mouseY = 0

            function animateParticles(event) {
                mouseX = event.clientX
                mouseY = event.clientY
            }

            const clock = new THREE.Clock()

            const tick = () => {

                const elapsedTime = clock.getElapsedTime()

                // Update objects
                sphere.rotation.y = .5 * elapsedTime
                particlesMesh.rotation.y = -.1 * elapsedTime

                if (mouseX > 0) {
                    particlesMesh.rotation.x = -mouseY * (elapsedTime * 0.00005)
                    particlesMesh.rotation.y = mouseX * (elapsedTime * 0.00005)
                }

                // Update Orbital Controls
                // controls.update()

                // Render
                renderer.render(scene, camera)

                // Call tick again on the next frame
                window.requestAnimationFrame(tick)
            }
            tick()

        })
    }
}
</script>

<style scoped lang="less">

</style>
